<template>
	<v-container>
		<v-row>
			<v-col>
				<div class="headline mb-1 text-center">PROMOTIONS</div>
			</v-col>
		</v-row>
		<v-row
			class="justify-center"
		>
			<v-col
				cols="12"
				md="10"
			>
				<v-carousel 
					v-model="carouselNum"
					v-if="resourceDataSet_.length>0"
				>
					<v-carousel-item
						v-for="(promtionPic,i) in promotionPics"
						:key="i"
						:src="promtionPic.image.path"
						reverse-transition="fade-transition"
						transition="fade-transition"
					>
					</v-carousel-item>
				</v-carousel>
			</v-col>
		</v-row>
		<v-row
			class="justify-center mt-3"
		>
			<v-col
				cols="12"
				md="10"
			>
				<v-row>
					<v-col
						v-for="(promotion,i) in resourceDataSet_"
						:key="i"
						col="6"
					>
						<v-card
							class="mx-auto my-12"
							width="700"
						>
							<v-img
								height="350"
								:src="promotion.image ? promotion.image.path : null"
							></v-img>

							<v-card-title>{{promotion.title}}</v-card-title>

							<v-card-text>
								<div class="my-4 text-subtitle-1" style="text-align: center;">
									{{promotion.content}}
								</div>
							</v-card-text>
						</v-card>
					</v-col>
				</v-row>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
import { resourceMixin } from "@/mixins/ResourceMixin"
import { errorHandlerMixin } from "@/mixins/ErrorHandlerMixin"

const DEFAULT_IMAGE_FILE_TYPE_ID = 11;

export default {
	components:{
	},
	mixins: [
		resourceMixin,
		errorHandlerMixin,
	],
	computed: {
		promotionPics()
		{
			return this.resourceDataSet_.filter(i => i.image)
		}
	},
	data(){
		return {
			carouselNum: 0,
		}
	},
	created(){
		this.initialize_()
	},
	methods:{
		getResourceData_(page = 1){
			let payload = {
				itemsPerPage: 99,
				is_archived: 0,
			}
			this.model_loading_ = true
			this.$api.getPromotionList(payload).then((res)=>{
				let { data, } = res.data.result
				this.resourceDataSet_ = [ ...this.resourceDataSet_, ...data ]
			}).catch((err)=>{
				this.errorHandler_(err)
			}).finally(()=>{
				this.model_loading_ = false
			})
		},
	}
}
</script>
<style>
.seamWindow{
	width: 35px;
	height: 35px;
}
</style>
